.most-common-symptom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.most-common-symptom-card {
  max-width: 300px;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 
  0 4px 6px #00000033,  /* sombra más cercana para dar relieve */
  0 8px 12px rgba(0, 0, 0, 0.15), /* sombra adicional para profundidad */
  0 12px 16px rgba(0, 0, 0, 0.1); /* sombra suave final */
  padding: 20px;
  text-align: center;
}

.most-common-symptom-card h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 15px;
}

.symptom-details p {
  font-size: 1.1em;
  color: #555;
  margin: 5px 0;
}

.symptom-details strong {
  color: #333;
}
