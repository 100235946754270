.dashboard-container {
  padding: 20px;
  background-color: #dfdfdf;
  border-radius: 12px; /* Agrega border-radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Opcional: añade sombra para destacar */
}

.dashboard-container h1 {
  margin: 0 0 20px 0; /* Asegúrate de que tenga un margen adecuado */
  text-align: center;
  font-size: 2.5em;
  color: #333;
}

.chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.chart {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart h2 {
  text-align: center;
  margin-bottom: 20px;
}
